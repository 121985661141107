import React, { useState, useEffect } from 'react';
import useLocalStorage from '../util/useLocalStorage';
import Video from './Video';
import Avatar from './Avatar';

// TODO: adaptive streaming; don't stream 720p+ for this thumbnail

export default function Livestreams(props) {
  const [addresses, setAddresses] = useState({});
  const [defaultChasers, setDefaultChasers] = useState([]);
  const [visible, setVisible] = useLocalStorage(
    window.matchMedia('(max-width: 1024px)').matches ? false : true
  );

  useEffect(() => {
    // TODO: move to https://docs.mapbox.com/api/search/#batch-geocoding batch for multi chasers
    // TODO: useMemo to reduce calls
    // TODO: rerun when radar time changes
    async function fetchAddresses() {
      let newAddresses = { ...addresses };
      for (let c of Object.keys(props.chasers)) {
        if (
          !newAddresses.hasOwnProperty(c) &&
          props.chasers[c].hasOwnProperty('location')
        ) {
          newAddresses[c] = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.chasers[c].location.lng},${props.chasers[c].location.lat}.json?types=place&access_token=pk.eyJ1IjoiaGlnaHdheXNhbmRoYWlsc3RvbmVzIiwiYSI6ImNrYXF6NXVydTAwaGYycXM1MzFzN2J3aXUifQ.PSStTFJfmcmPnrFi1IeJsA`
          )
            .then((r) => r.json())
            .then((d) => {
              return d.features[0]?.place_name
                .split(', ')
                .slice(0, 2)
                .join(', ');
            });
        }
      }
      setAddresses(newAddresses);
    }
    fetchAddresses();
  }, [props.chasers]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_HOST}/wp-json/wp/v2/options/default_chasers${
        props.station ? `/${props.station}` : ''
      }`
    )
      .then((r) => r.json())
      .then((data) => {
        setDefaultChasers(data);
      });
  }, []);

  const filteredApproved = Object.keys(props.chasers).filter((id) =>
    props.approvedChasers.length
      ? props.approvedChasers.includes(parseInt(id))
      : true
  );
  // .filter((id) => {
  //   let chaser = props.chasers[id];
  //   return chaser.hasOwnProperty('location') && chaser.hasOwnProperty('name');
  // });

  const filteredDefault = defaultChasers.filter(
    (c) => !Object.keys(props.chasers).includes(c.ID)
  );

  return (
    <div className={`Livestreams ${!visible && 'Livestreams--hidden'}`}>
      <header>
        {props.station === 'kake' && (
          <a href="https://www.kake.com/" target="_blank">
            <img
              src="/assets/kake.png"
              alt="KAKE"
              height="20"
              className="Livestreams__logo"
            />
          </a>
        )}
        {props.station === 'kalb' && (
          <a href="https://www.kalb.com/" target="_blank">
            <img
              src="/assets/kalb.svg"
              alt="KALB"
              height="28"
              className="Livestreams__logo"
            />
          </a>
        )}
        {props.station === 'storm-team-8' && (
          <a>
            <img
              src="/assets/storm-team-8.png"
              alt="Storm Team 8"
              height="28"
              className="Livestreams__logo"
            />
          </a>
        )}
        {!props.station && (
          <a href="https://highwaysandhailstones.com/" target="_blank">
            <svg
              width="52"
              height="18"
              viewBox="0 0 52 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="Livestreams__logo"
            >
              <path
                d="M16.2753 0.174516V17.8255H11.2359V10.6953H5.06514V17.8255H0V0.174516H5.06514V6.78116H11.2359V0.174516H16.2753Z"
                fill="white"
              />
              <path
                d="M29.8814 17.8255L28.313 15.9058C28.2273 16.0222 28.073 16.1884 27.8502 16.4044C27.3874 16.903 26.8046 17.2936 26.1018 17.5762C25.4162 17.8587 24.6363 18 23.7621 18C22.4937 18 21.4052 17.759 20.4968 17.277C19.5883 16.7784 18.9027 16.1136 18.4399 15.2825C17.9771 14.4349 17.7457 13.4958 17.7457 12.4654C17.7457 11.1025 18.1056 9.96399 18.8255 9.04986C19.5626 8.11911 20.5053 7.52909 21.6538 7.27978L21.3452 6.75623C20.9339 5.92521 20.7282 5.11911 20.7282 4.33795C20.7282 3.59003 20.9424 2.88366 21.371 2.21884C21.7995 1.55402 22.4251 1.02216 23.2479 0.623268C24.0878 0.207756 25.0905 0 26.2561 0C29.1701 0 31.0213 1.12188 31.8098 3.36565C32.1012 4.2133 32.2211 5.11911 32.1697 6.0831H27.4645C27.4645 5.68421 27.4388 5.36011 27.3874 5.1108C27.336 4.41274 26.9932 4.06371 26.359 4.06371C26.0847 4.06371 25.8619 4.1385 25.6905 4.28809C25.5362 4.43767 25.4591 4.63712 25.4591 4.88643C25.4591 5.10249 25.5019 5.30194 25.5876 5.48476C25.6905 5.66759 25.8704 5.93352 26.1276 6.28255L34.9208 17.8255H29.8814ZM23.7878 13.9363C24.302 13.9363 24.7134 13.795 25.022 13.5125C25.3476 13.2133 25.5105 12.831 25.5105 12.3657C25.5105 11.9003 25.3391 11.5263 24.9963 11.2438C24.6534 10.9446 24.2335 10.795 23.7364 10.795C23.2393 10.795 22.8365 10.9363 22.528 11.2188C22.2194 11.4848 22.0652 11.8587 22.0652 12.3407C22.0652 12.8227 22.228 13.2133 22.5537 13.5125C22.8794 13.795 23.2907 13.9363 23.7878 13.9363Z"
                fill="white"
              />
              <path
                d="M52 0.174516V17.8255H46.9606V10.6953H40.7899V17.8255H35.7247V0.174516H40.7899V6.78116H46.9606V0.174516H52Z"
                fill="white"
              />
            </svg>
          </a>
        )}
        <button onClick={() => setVisible(!visible)}>
          {visible ? (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10.2929 4.29294L5.58582 9.00005L10.2929 13.7072L11.7071 12.2929L8.41424 9.00005L11.7071 5.70715L10.2929 4.29294Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM7.70708 4.29294L12.4142 9.00005L7.70708 13.7072L6.29286 12.2929L9.58576 9.00005L6.29286 5.70715L7.70708 4.29294Z"
                fill="white"
              />
            </svg>
          )}
        </button>
      </header>

      {filteredApproved && filteredApproved.length > 0 && (
        <>
          <h4>Chasing</h4>
          {filteredApproved.map((id) => {
            let chaser = props.chasers[id];

            if (
              chaser.hasOwnProperty('location') &&
              chaser.hasOwnProperty('name')
            ) {
              return (
                <div
                  key={id}
                  className="Live__stream"
                  onClick={() => {
                    if (chaser.live) {
                      props.handleSetVideo(id);
                    } else {
                      props.handleSetVideo(false);
                    }
                    // TODO: perhaps just one "focusOnChaser" function is what we need
                    // ... to set state at the top and let props flow down
                    props.handleFlyToChaser(id);
                  }}
                >
                  <div
                    className={`Live__chaser ${
                      id === props.tracking && 'Live__chaser--tracking'
                    }
                ${chaser.live && 'Live__chaser--live'}
                `}
                  >
                    <Avatar chaser={chaser} />
                    {visible && (
                      <div>
                        <p>{chaser.name}</p>
                        <p>{addresses[id]}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </>
      )}

      {filteredDefault && filteredDefault.length > 0 && (
        <>
          <h4>Not Chasing</h4>
          {filteredDefault.map((offlineChaser) => (
            <div key={offlineChaser.ID} className="Live__stream">
              <a
                className={`Live__chaser Live__chaser--offline`}
                href={`${process.env.REACT_APP_HOST}/chasers/${offlineChaser.user_nicename}`}
                target="_blank"
                rel="noreferrer"
              >
                <Avatar
                  chaser={{
                    name: offlineChaser.display_name,
                    avatar_url: offlineChaser.profile_picture?.sizes?.thumbnail,
                  }}
                />
                {visible && (
                  <div>
                    <p>{offlineChaser.display_name}</p>
                  </div>
                )}
              </a>
            </div>
          ))}
        </>
      )}

      {(!props.station || props.showSignup) && (
        <aside className="Join">
          <img
            srcset="/assets/hh-ampersand-logo-180.png 180w,
                  /assets/hh-ampersand-logo-360.png 360w"
            sizes="180px"
            src="/assets/hh-ampersand-logo-180.png"
            alt=""
          />
          <h2>Highways &amp; Hailstones</h2>
          <p>Connecting storm chasers &amp; weather enthusiasts</p>

          <a target="_blank" href="https://highwaysandhailstones.com/join/">
            Join Today &nbsp; &rarr;
          </a>
        </aside>
      )}
    </div>
  );
}
