import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import ReactMapGL, {
  //Marker,
  Source,
  Layer,
  //FlyToInterpolator,
  //LinearInterpolator,
  //TRANSITION_EVENTS,
  WebMercatorViewport,
  NavigationControl,
} from 'react-map-gl';
import './HHMap.css';

import DebugPanel from './components/DebugPanel';
import Chaser from './components/Chaser';
import Video from './components/Video';
import Livestreams from './components/Livestreams';
//import UpdatePanel from './components/UpdatePanel';
import FetchableUpdatePanel from './components/FetchableUpdatePanel';
import LiveStreamPanel from './components/LiveStreamPanel';

import { getRadarAsBase64, radarCoords } from './util/weatherlogics';

import { ReactComponent as BoundsSVG } from './svg/bounds.svg';

const token =
  'pk.eyJ1IjoiaGlnaHdheXNhbmRoYWlsc3RvbmVzIiwiYSI6ImNrYXF6NXVydTAwaGYycXM1MzFzN2J3aXUifQ.PSStTFJfmcmPnrFi1IeJsA';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HHMap(props) {
  let query = useQuery();
  let { station, approved } = useParams();

  const showTrack = query.get('track') === 'false' ? false : true;
  const showEvents = query.get('events') === 'false' ? false : true;
  const showSignup = query.get('signup') === 'true' ? true : false;

  const [viewport, setViewport] = useState({
    latitude: 41,
    longitude: -96,
    zoom: 4,
    bearing: 0,
    pitch: 0,
  });
  const [chasers, setChasers] = useState({});
  const [approvedChasers, setApprovedChasers] = useState([]);
  const [radar, setRadar] = useState(false);
  const [oldRadar, setOldRadar] = useState(false);
  const [video, setVideo] = useState(false);
  const [update, setUpdate] = useState(false);
  const [tracking, setTracking] = useState(false);
  const [bounds, setBounds] = useState(false);
  const [loadingRadar, setLoadingRadar] = useState(false);

  var envHost = process.env.REACT_APP_WS.replace('http', 'ws');

  useEffect(() => {
    var socket = new WebSocket(envHost);

    socket.onmessage = (data) => {
      const chasers = JSON.parse(data.data);
      setChasers(chasers);
    };

    return function cleanup() {
      socket.close();
    };
  }, []);

  // TODO: setVideo and setUpdate at the same time?
  function handleSetVideo(chaser_id) {
    setUpdate(
      false
    ); /* TODO: merge live (streaming) video and updates into a single "panel" */

    // As of now, setVideo takes a chaser_id,
    // so we can toggle it off when live is done
    setVideo(chaser_id);
  }
  function handleSetUpdate(update) {
    setUpdate(update);
    setVideo(
      false
    ); /* TODO: merge live (streaming) video and updates into a single "panel" */
  }
  function handleNextUpdate() {}
  function handlePrevUpdate() {}

  function handleBackToLive() {
    setUpdate(false);
  }
  function handleSetToBounds() {
    var coords = Object.values(chasers).reduce(
      (locs, c) => {
        return {
          lats: [c.location.lat, ...locs.lats],
          lons: [c.location.lng, ...locs.lons],
        };
      },
      {
        lats: [],
        lons: [],
      }
    );
    let bounds = [
      [Math.min(...coords.lons), Math.min(...coords.lats)],
      [Math.max(...coords.lons), Math.max(...coords.lats)],
    ];
    console.log(bounds);

    // TODO: when Helena is chasing, the 250/300 padding is too zoomed out to make this work
    // the fix is to make the sidebar flex against the map, instead of overlayed.
    try {
      let newViewport = new WebMercatorViewport(viewport).fitBounds(bounds, {
        padding: 50,
        offset: [250, 0],
      });
      setTracking(false);
      setViewport(newViewport);
      setBounds(bounds);
    } catch (e) {
      console.log(e);
    }

    /*
    setViewport({
      //transitionInterpolator: new FlyToInterpolator(),
      //transitionDuration: 'auto',
      //transitionInterruption: TRANSITION_EVENTS.UPDATE,
      ...newViewport,
    });
    */
  }

  function handleFlyToChaser(id) {
    if (!chasers.hasOwnProperty(id)) return;
    setBounds(false);
    setTracking(id);
    if (id && chasers[id].hasOwnProperty('location')) {
      setViewport({
        ...viewport,
        zoom: Math.max(viewport.zoom, 9),
        longitude: chasers[id].location.lng,
        latitude: chasers[id].location.lat,
      });
    }
  }

  async function fetchRadar(time) {
    if (typeof time === 'undefined') {
      setLoadingRadar(true);
      var radar = await getRadarAsBase64(new Date());
      setLoadingRadar(false);
      setRadar(radar);
    } else {
      setLoadingRadar(true);
      var radar = await getRadarAsBase64(new Date(time), 0);
      setLoadingRadar(false);
      setOldRadar(radar);
    }
  }

  useEffect(() => {
    if (approved)
      setApprovedChasers(approved.split(',').map((c) => parseInt(c)));
  }, [approved]);

  useEffect(() => {
    var interval = setInterval(() => {
      if (true || new Date().getMinutes() % 10 == 1) {
        fetchRadar();
      }
    }, 1000 * 60 * 3);
    fetchRadar();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (update && update.hasOwnProperty('update')) {
      fetchRadar(update.update.date_gmt + '.000+00:00');
    } else {
      setOldRadar(false);
    }
  }, [update]);

  var showRadar = false;
  if (oldRadar) {
    showRadar = oldRadar;
  } else {
    showRadar = radar;
  }

  return (
    <div className="HHMap">
      <ReactMapGL
        {...viewport}
        //transitionInterruption={TRANSITION_EVENTS.UPDATE}
        mapboxApiAccessToken={token}
        width="100vw"
        height="100vh"
        mapStyle="mapbox://styles/highwaysandhailstones/ckfa12whj308819lnsd9h5y0u"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        onInteractionStateChange={(interactionState) => {
          if (interactionState.isDragging) {
            setTracking(false);
            setBounds(false);
          }
        }}
        attributionControl={false}
        onClick={() => setUpdate(false)}
      >
        {Object.keys(chasers)
          .filter((id) =>
            approvedChasers.length
              ? approvedChasers.includes(parseInt(id))
              : true
          )
          .map((id) => {
            const chaser = chasers[id];

            if (
              chaser.hasOwnProperty('location') &&
              chaser.hasOwnProperty('name')
            ) {
              return (
                <Chaser
                  {...chaser}
                  key={id}
                  id={id}
                  handleSetVideo={handleSetVideo}
                  handleSetUpdate={handleSetUpdate}
                  handleFlyToChaser={handleFlyToChaser}
                  selectedUpdate={update.id}
                  tracking={tracking == id}
                  playing={video !== false && video === chaser.id}
                  showTrack={showTrack}
                  showEvents={showEvents}
                />
              );
            } else {
              return null;
            }
          })}

        {showRadar ? (
          <>
            <Source
              id="radar"
              type="image"
              url={showRadar.data}
              coordinates={radarCoords}
            >
              <Layer id="rain" type="raster" beforeId="place-hamlet" />
            </Source>
            <DebugPanel
              time={showRadar.time}
              old={!!oldRadar}
              handleBackToLive={handleBackToLive}
              loading={loadingRadar}
            />
          </>
        ) : (
          ''
        )}
        {Object.keys(chasers).length > 1 && (
          <div class={`Bounds ${bounds && 'Bounds--set'}`}>
            <button
              onClick={handleSetToBounds}
              disabled={Object.keys(chasers).length < 2}
            >
              <BoundsSVG />
            </button>
          </div>
        )}
        <NavigationControl
          showCompass={false}
          style={{
            right: 16,
            bottom: Object.keys(chasers).length > 1 ? 94 : 16,
          }}
        />
      </ReactMapGL>

      <div className="Sidebar">
        <Livestreams
          chasers={chasers}
          handleSetVideo={handleSetVideo}
          handleFlyToChaser={handleFlyToChaser}
          video={video}
          tracking={tracking}
          approvedChasers={approvedChasers}
          station={station}
          showSignup={showSignup}
        />
        {video && chasers.hasOwnProperty(video) && chasers[video].live ? (
          <LiveStreamPanel
            handleSetUpdate={() => {
              setVideo(false);
            }}
          >
            <Video
              key={chasers[video].live}
              playback_id={chasers[video].live}
              video_id={chasers[video].live}
              video_title={`${chasers[video].name} Livestream`}
            />
          </LiveStreamPanel>
        ) : (
          ''
        )}
        {update && chasers.hasOwnProperty(update.chaser_id) && (
          <FetchableUpdatePanel
            type={update.type}
            id={update.id}
            update={update}
            handleSetUpdate={handleSetUpdate}
            handleSetVideo={handleSetVideo}
            updates={chasers[update.chaser_id].updates}
            chaser={chasers[update.chaser_id]}
            key={update.id}
          />
        )}
      </div>
    </div>
  );
}

export default HHMap;
