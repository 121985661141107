import React, { useState, useEffect } from 'react';

import { Marker, Source, Layer } from 'react-map-gl';
import classNames from 'classnames';

import Update from './Update.js';
import Avatar from './Avatar.js';
import ChasePath from './ChasePath.js';

import { ReactComponent as ChaserPinSVG } from '../svg/chaser-pin.svg';

export default function Chaser(props) {
  var HOST = process.env.REACT_APP_HOST;
  var WS = process.env.REACT_APP_WS;

  const url = `${HOST}/wp-json/wp/v2/users/${props.id}`;
  //const redisRouteUrl = `${WS}/route/${props.id}/${props.chase_id}`;
  const prevRouteUrl = `${WS}/route/${props.id}`;
  //const prevUpdateUrl = `${WS}/updates/${props.id}/${props.chase_id}`;

  const [route, setRoute] = useState(false);

  useEffect(() => {
    if (route && Array.isArray(route))
      setRoute([...route, ...[[props.location.lng, props.location.lat]]]);
  }, [props.location]);

  function handleClick() {
    if (props.live) {
      props.handleSetVideo(props.id);
    }
    props.handleFlyToChaser(props.id);
  }

  useEffect(() => {
    fetch(prevRouteUrl)
      .then((r) => r.json())
      .then((data) => {
        //let path = data.map((p) => [p.data.longitude, p.data.latitude]);
        let path = data;
        //console.log(path);
        setRoute(path);
      });
  }, []);

  return (
    <>
      {props.showTrack && (
        <ChasePath route={route} id={props.id} tracking={props.tracking} />
      )}
      <Marker
        latitude={props.location.lat}
        longitude={props.location.lng}
        offsetTop={-56}
        offsetLeft={-24}
        className="ChaserMarker"
      >
        <div
          className={classNames('ChaserPin', {
            'ChaserPin--live': props.live,
            'ChaserPin--tracking': props.tracking,
            'ChaserPin--playing': props.playing,
          })}
          onClick={handleClick}
        >
          <ChaserPinSVG />
          <div className="Avatar">
            <Avatar chaser={{ ...props }} />
          </div>
          {props.live ? <span>Live</span> : ''}
        </div>
      </Marker>
      {props.showEvents && props.updates
        ? props.updates.map((update) => {
            return (
              <Update
                key={update.update.id}
                id={update.update.id}
                {...update}
                chaser_id={props.id}
                handleSetUpdate={props.handleSetUpdate}
                selectedUpdate={props.selectedUpdate}
              />
            );
          })
        : ''}
    </>
  );
}
