import React, { useState, useEffect } from 'react';

export default function DebugPanel(props) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000 * 60 * 0.5);

    return () => clearInterval(intervalID);
  }, [setCurrentTime]);

  return (
    <div
      className={`DebugPanel ${
        props.old && props.handleBackToLive && 'DebugPanel--old'
      }`}
    >
      {props.loading ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="8.042%"
              y1="0%"
              x2="65.682%"
              y2="23.865%"
              id="a"
            >
              <stop stop-color="#fff" stop-opacity="0" offset="0%" />
              <stop stop-color="#fff" stop-opacity=".631" offset="63.146%" />
              <stop stop-color="#fff" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)">
              <path
                d="M36 18c0-9.94-8.06-18-18-18"
                id="Oval-2"
                stroke="url(#a)"
                stroke-width="2"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </path>
              <circle fill="#fff" cx="36" cy="18" r="1">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </g>
        </svg>
      ) : (
        <div className="DebugPanel__time">
          <span>{props.old ? 'Radar From' : 'Current Time'}</span>
          <time>
            {props.old
              ? props.time.toISOString().substr(11, 5)
              : currentTime.toISOString().substr(11, 5)}
            Z
          </time>
        </div>
      )}
      {props.old && props.handleBackToLive && (
        <button onClick={() => props.handleBackToLive}>Back to Now</button>
      )}
    </div>
  );
}
