import React, { useState, useRef, useEffect } from 'react';

export default function Timeline(props) {
  const startTime = Math.round(props.chase.route[0].timestamp);
  const endTime = Math.round(
    props.chase.route[props.chase.route.length - 1].timestamp
  );
  const timeLength = endTime - startTime;

  const [hoverProgress, setHoverProgress] = useState(0);
  const scrubber = useRef(null);

  // TODO: all these Math.min, Math.max
  const handleTimelineHover = (e) => {
    setHoverProgress(
      (e.nativeEvent.offsetX / (scrubber.current.clientWidth - 3)) * 100
    );
  };
  const handleTimelineClick = (e) => {
    props.handleSetProgress(
      Math.max(
        0,
        (e.nativeEvent.offsetX / (scrubber.current.clientWidth - 3)) * 100
      )
    );
  };
  const handleTouchEnd = (e) => {
    props.handleSetProgress(Math.max(0, hoverProgress));
  };

  useEffect(() => {
    props.handleSetRabbit(hoverProgress);
  }, [hoverProgress]);

  const livestreams = props.chase.meta?.livestream_events.map((l) => {
    return {
      ...l,
      end_at: l.created_at + Math.floor(l.duration),
    };
  });

  return (
    <div className="Timeline">
      <div class="Timeline__updates">
        {props.updates.map((u) => {
          let progress =
            (props.updatesIndex[u.id] / props.chase.route.length) * 100;
          return (
            <div
              className="Timeline__update"
              style={{
                left: progress + '%',
                background: props.update.id === u.id ? 'white' : undefined,
                opacity: props.progress
                  ? progress > props.progress
                    ? 0.2
                    : 1
                  : 0.2,
              }}
              onClick={() =>
                props.handleSetUpdate({ update: u, id: u.id, type: u.type })
              }
            ></div>
          );
        })}

        {livestreams &&
          livestreams.map((l) => (
            <div
              className="Timeline__update Timeline__update--live"
              style={{
                left: l.created_at / timeLength + '%',
              }}
              onClick={() => props.handleSetLivestream(l.playback_ids[0].id)}
            ></div>
          ))}
        {false &&
          livestreams.map((l) => {
            return l.recording_times.map((r) => (
              <div
                className="Timeline__update Timeline__update--live"
                style={{
                  left: r.started_at.seconds / timeLength + '%',
                  background: 'green',
                }}
                onClick={() => props.handleSetLivestream(l.playback_ids[0].id)}
              ></div>
            ));
          })}
      </div>
      <div
        className="Timeline__scubber"
        onClick={handleTimelineClick}
        onTouchEnd={handleTouchEnd}
        onPointerMove={handleTimelineHover}
        onMouseLeave={() => setHoverProgress(0)}
        ref={scrubber}
      >
        <div
          className="Timeline__progress"
          style={{ width: Math.min(100, props.progress) + '%' }}
        >
          <span class="Timeline__rabbit"></span>
        </div>

        {livestreams &&
          livestreams.map((l) => (
            <div
              className="Timeline__progress Timeline__progress--live"
              style={{
                width: Math.max((l.duration / timeLength) * 100, 0.1) + '%',
                left: l.created_at / timeLength + '%',
              }}
              onClick={() => props.handleSetLivestream(l.playback_ids[0].id)}
            ></div>
          ))}

        {false &&
          livestreams.map((l) => {
            return l.recording_times.map((r) => (
              <div
                className="Timeline__progress Timeline__progress--live"
                style={{
                  width: Math.max((r.duration / timeLength) * 100, 0.1) + '%',
                  left: r.started_at.seconds / timeLength + '%',
                  background: 'green',
                }}
                onClick={() => props.handleSetLivestream(l.playback_ids[0].id)}
              ></div>
            ));
          })}
        <div
          className="Timeline__hover"
          style={{ width: Math.min(100, hoverProgress) + '%' }}
        ></div>
      </div>
    </div>
  );
}
