import React, { useEffect, useRef } from 'react';
import mux from 'mux-embed';

import Hls from 'hls.js';

export default function Video(props) {
  const video = useRef(null);

  const handleTimeChange = (e) => {
    console.log(
      video.current.currentTime,
      video.current.duration,
      (video.current.currentTime / video.current.duration) * 100 + '%'
    );
    //props.handleSetProgress();
  };

  useEffect(() => {
    const playbackId = props.playback_id;
    const url = props.playback_id
      ? 'https://stream.mux.com/' + playbackId + '.m3u8'
      : props.url;
    console.log(`Mounting ${url}`);

    var hls = null;
    if (video.current.canPlayType('application/vnd.apple.mpegurl')) {
      video.current.src = url;
      video.current.addEventListener('loadedmetadata', function () {
        video.current.play();
      });
    } else if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.current.play();
      });
    }

    const videoRef = video.current;

    return () => {
      console.log('Unmounting Video');
      videoRef.pause();
      if (hls) {
        hls.stopLoad();
        hls.destroy();
      }
    };
  }, [props.playback_id, props.url]);

  useEffect(() => {
    if (video.current) {
      const initTime = Date.now();

      const video_id = () => {
        if (props.playback_id) return props.playback_id;
        if (props.url) {
          const s = props.url.split('/');
          if (s && s.length) return s[s.length - 1]?.split('.')[0];
        }
        return '';
      };

      mux.monitor(video.current, {
        debug: false,
        data: {
          env_key: process.env.REACT_APP_MUX_DATA,

          // Player Metadata
          player_name: 'HH Live', // ex: 'My Main Player'
          player_init_time: initTime,

          // Video Metadata
          video_id: props.video_id, // ex: 'abcd123'
          video_title: props.video_title,
          // video_series: '',
          video_stream_type: props.playback_id ? 'live' : 'on-demand', // 'live' or 'on-demand'
        },
      });
    }
  }, [video, props.playback_id, props.url, props.video_title, props.video_id]);

  return (
    <div className="Video">
      <video
        controls
        preload="none"
        ref={video}
        muted={props.muted}
        playsInline
        autoPlay
        controlsList="nodownload"
        disablePictureInPicture
        onTimeUpdate={props.liveReplay ? (e) => handleTimeChange(e) : undefined}
      ></video>
    </div>
  );
}
