import React from 'react';
import { Marker, Source, Layer } from 'react-map-gl';

function geoJSON(route) {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route,
        },
      },
    ],
  };
}

export default function ChasePath(props) {
  return (
    <Source type="geojson" data={geoJSON(props.route)}>
      <Layer
        {...{
          id: `line-animation-base-${props.id}`,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#192337',
            'line-width': 6,
            'line-opacity': 1,
          },
          /*
          paint: {
            'line-color': '#15192F',
            'line-width': 4,
            'line-opacity': 1,
          },
          */
        }}
      />
      <Layer
        {...{
          id: `line-animation-${props.id}`,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': props.tracking ? '#64BEFF' : '#0071C2',
            'line-width': 4,
            'line-opacity': 1,
          },
          /*
            'line-color': '#818CBB',
          },
          */
        }}
      />
    </Source>
  );
}
