import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './HHWall.css';
import Video from './components/Video';

const token =
  'pk.eyJ1IjoiaGlnaHdheXNhbmRoYWlsc3RvbmVzIiwiYSI6ImNrYXF6NXVydTAwaGYycXM1MzFzN2J3aXUifQ.PSStTFJfmcmPnrFi1IeJsA';

const statecodes = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  'Yukon Territory': 'YT',

  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

const HLSDetails = ({ live, nicename }) => {
  const inputRef = useRef(null);

  return (
    <div className="Wall__hls">
      HLS:
      <input
        type="text"
        value={`${process.env.REACT_APP_HOST}/chasers/${nicename}.m3u8`}
        ref={inputRef}
      />
      <button
        onClick={() => {
          inputRef.current.select();
          document.execCommand('copy');
          inputRef.current.blur();
        }}
      >
        <img src="/assets/clipboard.svg" alt="Copy to Clipboard" />
        &nbsp;Copy
      </button>
    </div>
  );
};

function HHWall(props) {
  const HOST = process.env.REACT_APP_HOST;
  let { station, approved } = useParams();
  console.log(station);
  console.log(approved);

  const [addresses, setAddresses] = useState({});
  const [nicenames, setNicenames] = useState({});
  const [chasers, setChasers] = useState({});
  const [approvedChasers, setApprovedChasers] = useState([]);

  var envHost = process.env.REACT_APP_WS.replace('http', 'ws');

  useEffect(() => {
    // TO: abstract away to share between HHMap and HHWall
    var socket = new WebSocket(envHost);

    socket.onmessage = (data) => {
      const chasers = JSON.parse(data.data);
      setChasers(chasers);
    };

    return function cleanup() {
      socket.close();
    };
  }, []);

  useEffect(() => {
    // TODO: move to https://docs.mapbox.com/api/search/#batch-geocoding batch for multi chasers
    // TODO: duped from Livestreams.js
    async function fetchAddresses() {
      let newAddresses = { ...addresses };
      for (let c of Object.keys(chasers)) {
        if (
          !newAddresses.hasOwnProperty(c) &&
          chasers[c].hasOwnProperty('location')
        ) {
          newAddresses[c] = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${chasers[c].location.lng},${chasers[c].location.lat}.json?types=place&access_token=pk.eyJ1IjoiaGlnaHdheXNhbmRoYWlsc3RvbmVzIiwiYSI6ImNrYXF6NXVydTAwaGYycXM1MzFzN2J3aXUifQ.PSStTFJfmcmPnrFi1IeJsA`
          )
            .then((r) => r.json())
            .then((d) => {
              let place = d.features[0]?.place_name.split(', ').slice(0, 2);
              let state = statecodes[place[1]];
              if (!state) state = place[1];
              return `${place[0]}, ${state}`;
            });
        }
      }
      setAddresses(newAddresses);
    }
    async function fetchNicenames() {
      let newNicenames = { ...nicenames };
      for (let c of Object.keys(chasers)) {
        if (
          !newNicenames.hasOwnProperty(c) &&
          chasers[c].hasOwnProperty('location')
        ) {
          newNicenames[c] = await fetch(`${HOST}/wp-json/wp/v2/users/${c}`)
            .then((r) => r.json())
            .then((u) => {
              return u.slug;
            });
        }
      }
      setNicenames(newNicenames);
    }
    fetchAddresses();
    fetchNicenames();
  }, [chasers]);

  useEffect(() => {
    if (approved)
      setApprovedChasers(approved.split(',').map((c) => parseInt(c)));
  }, [approved]);

  console.log(chasers);
  return (
    <div className="HHWall">
      {Object.keys(chasers)
        .filter((id) => {
          console.log(id);
          return approvedChasers.includes(parseInt(id));
        })
        .map((id) => {
          const chaser = chasers[id];
          return (
            <article className="Wall__video">
              <h2>
                {chaser.name}
                <span>{addresses[id]}</span>
              </h2>
              {chaser.live ? (
                <figure>
                  <Video playback_id={chaser.live} />
                </figure>
              ) : (
                <figure>
                  <figcaption>
                    <em>Not Streaming</em>
                  </figcaption>
                </figure>
              )}
              {chaser.live && (
                <HLSDetails live={chaser.live} nicename={nicenames[id]} />
              )}
            </article>
          );
        })}
    </div>
  );
}

export default HHWall;
