import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Marker } from 'react-map-gl';
import { ReactComponent as PinSVG } from '../svg/pin-blue.svg';
import { ReactComponent as TextSVG } from '../svg/pin-text.svg';
import Video from './Video.js';

export default function Update(props) {
  const { update_id } = useParams();

  var type = props.update.type;
  if (props.update.type === 'image') type = 'media';

  const url = `${process.env.REACT_APP_HOST}/wp-json/wp/v2/${type}/${props.id}`;
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (!props.update.hasOwnProperty('meta')) {
      fetch(url)
        .then((r) => r.json())
        .then((data) => {
          setUpdate(data);
        });
    } else {
      setUpdate(props.update);
    }
  }, []);

  useEffect(() => {
    if (update && update_id && update_id == props.update.id) {
      props.handleSetUpdate({
        update,
        type,
        id: props.update.id,
        chaser_id: props.chaser_id,
      });
    }
  }, [update, update_id, type]);

  function thumbnail() {
    if (props.update.type === 'video') return `${update.meta.preview}?width=64`;
    if (
      (props.update.type === 'image' || props.update.type === 'attachment') &&
      props.update.media_type !== 'file'
    ) {
      if (update.media_details?.sizes?.thumbnail) {
        return update.media_details.sizes.thumbnail.source_url;
      } else {
        return update.source_url;
      }
    }
  }

  return (
    <Marker
      latitude={props.location.lat}
      longitude={props.location.lng}
      offsetTop={-40}
      offsetLeft={-16}
    >
      {update && (
        <div
          className={`HHMarker HHMarker--update HH--update--${props.update.type}`}
          onClick={() => {
            if (props.handleSetUpdate)
              props.handleSetUpdate({
                update,
                type,
                id: props.update.id,
                chaser_id: props.chaser_id,
                skip_fetch: true,
              });
          }}
        >
          <div
            className={`Update__pin ${
              props.selectedUpdate === props.update.id ||
              props.selectedUpdate === props.id
                ? 'Update__pin--selected'
                : ''
            }`}
          >
            {props.update.type === 'text' ? (
              <TextSVG />
            ) : (
              <>
                <PinSVG />
                <img src={thumbnail()} height="28" width="28" alt="" />
                {props.update.type === 'video' && (
                  <svg
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="Update__pin__video"
                  >
                    <path
                      d="M10.8572 6.15385L2.29453 12.0663C1.41152 12.676 0.190512 12.0605 0.190512 11.0057L0.190513 1.30203C0.190513 0.247196 1.41153 -0.368311 2.29454 0.241401L10.8572 6.15385Z"
                      fill="#1E9BF5"
                    />
                  </svg>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </Marker>
  );
}
