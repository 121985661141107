import { useMemo } from 'react';

export default function Avatar({ chaser }) {
  const hashedHSL = useMemo(() => {
    var hash = 0;
    if (!chaser?.name || chaser.name.length === 0) return hash;
    for (var i = 0; i < chaser.name.length; i++) {
      let code = parseInt(chaser.name[i], 36);
      if (Number.isInteger(code)) hash = hash + code;
    }
    return Math.abs(hash % 360);
  }, [chaser.name]);
  if (!chaser.name) return <img alt="" />;
  const [first, last] = chaser.name.split(' ');

  return chaser.avatar_url ? (
    <img src={chaser.avatar_url} alt={chaser.name} />
  ) : (
    <div
      className="FakeAvatar Avatar__blank"
      style={{ background: `hsla(${hashedHSL}, 29%, 74%, 0.8)` }}
    >
      {first && first.length && first[0]}
      {last && last.length && last[0]}
    </div>
  );
}
