import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ReactMapGL, {
  Marker,
  Source,
  Layer,
  WebMercatorViewport,
  NavigationControl,
} from 'react-map-gl';
import './HHMap.css';
import Panel from './Panel';
import DebugPanel from './components/DebugPanel';
import Update from './components/Update';
import Timeline from './components/Timeline';
import UpdatePanel from './components/UpdatePanel';
import Avatar from './components/Avatar';
import LiveStreamPanel from './components/LiveStreamPanel';
import Video from './components/Video';

import { getRadarAsBase64, radarCoords } from './util/weatherlogics';

const token =
  'pk.eyJ1IjoiaGlnaHdheXNhbmRoYWlsc3RvbmVzIiwiYSI6ImNrYXF6NXVydTAwaGYycXM1MzFzN2J3aXUifQ.PSStTFJfmcmPnrFi1IeJsA';

function HHReplay(props) {
  const HOST = process.env.REACT_APP_HOST;
  let { chase_id } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [chase, setChase] = useState(false);
  const [chaser, setChaser] = useState(false);
  const [viewport, setViewport] = useState({});

  const [updates, setUpdates] = useState(false);
  const [updatesIndex, setUpdatesIndex] = useState({});

  // Coordinates are a [lon, lat] version of chase.route without timestamps
  const [coordinates, setCoordinates] = useState([]);

  const [progress, setProgress] = useState(false);
  const [progressGeojson, setProgressGeojson] = useState(false);

  const [rabbit, setRabbit] = useState(false);
  const [update, setUpdate] = useState(false);

  const [oldRadar, setOldRadar] = useState(false);
  const [livestream, setLivestream] = useState(false);

  function handleSetUpdate(update) {
    console.log(update);
    setUpdate(update);
  }

  const handleSetProgress = (p) => {
    setProgress(p);
    setUpdate(false);
    // if p within livestream bounds, setLivestream
  };

  const handleSetLivestream = (playback_id) => {
    setUpdate(false);
    setLivestream(playback_id);
  };

  const indexFromProgress = useCallback(
    (p) => {
      let i = Math.min(
        chase.route.length - 1,
        Math.round((chase.route.length * p) / 100)
      );
      if (i < 0) {
        return 0;
      }
      return i;
    },
    [chase.route]
  );

  const handleSetRabbit = (p) => {
    let index = indexFromProgress(p);

    if (index > 0) {
      setRabbit(index);
    } else {
      setRabbit(false);
    }
  };

  async function fetchRadar(time) {
    if (typeof time === 'undefined') {
      setOldRadar(false);
    } else {
      var radar = await getRadarAsBase64(time, 0);
      setOldRadar(radar);
    }
  }

  useEffect(() => {
    if (update !== false) {
      setProgress((updatesIndex[update.id] / chase.route.length) * 100);
    } else {
      console.log('Update was false... what?');
    }
  }, [update, updatesIndex, chase]);

  useEffect(() => {
    if (progress === false || typeof progress === 'undefined') return;
    let i = indexFromProgress(progress);
    if (chase && chase.route[i].hasOwnProperty('timestamp')) {
      setOldRadar('loading');
      // Hack for Travis' bad timestamps from reconstructed log
      let time = chase.route[i].timestamp;
      if (time.toString().length < 11) time = time * 1000;
      // End Hack
      fetchRadar(new Date(time));
    } else {
      setOldRadar(false);
    }

    setProgressGeojson({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates.slice(
              0,
              Math.round(indexFromProgress(progress)) + 1
            ),
          },
        },
      ],
    });
  }, [progress, chase, coordinates, indexFromProgress]);

  const fetchData = useCallback(async () => {
    const res = await fetch(
      `${HOST}/wp-json/wp/v2/posts/${chase_id}?_embed=author`
    );
    res
      .json()
      .then((data) => {
        const coordinates = data.meta.chase_route.map((r) => [r.lon, r.lat]);
        setCoordinates(coordinates);
        const bounds = [
          [
            Math.min(...coordinates.map((c) => c[0])),
            Math.max(...coordinates.map((c) => c[1])),
          ],
          [
            Math.max(...coordinates.map((c) => c[0])),
            Math.min(...coordinates.map((c) => c[1])),
          ],
        ];
        setChase({
          route: data.meta.chase_route,
          geoJSON: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: coordinates,
                },
              },
            ],
          },
          bounds: bounds,
          meta: data.meta,
          events: data.meta.chase_events,
        });

        let author = data._embedded.author[0];
        setChaser({
          id: author.id,
          name: author.name,
          avatar_url: author.profile_picture,
        });

        setLoaded(true);
        if (!progress) {
          setProgress(0);
        }
        setViewport({
          ...new WebMercatorViewport({
            width: 800,
            height: 600,
          }).fitBounds(bounds, {
            padding: 20,
            offset: [0, -100],
          }),
        });
      })
      .catch((e) => console.log(e));
  }, [progress, HOST, chase_id]);

  const fetchUpdates = useCallback(async () => {
    let url = `${HOST}/wp-json/wp/v2/updates/${chase.events.join('_')}`;
    let api = await fetch(url);
    let data = await api.json();
    console.log('Fetch updates');
    setUpdates(data);

    // let lasti = 0;
    let updatesToIndex = {};

    data.forEach((u) => {
      let i = chase.route.findIndex((p) => {
        let time = new Date(u.date_gmt + '.000+00:00').getTime();
        if (p.timestamp > time) {
          return true;
        }
        return false;
      });
      if (i > 0) {
        updatesToIndex[u.id] = i;
      } else {
        updatesToIndex[u.id] = chase.route.length - 1;
      }
    });
    console.log(new Date(chase.route[0].timestamp));

    console.log('Updates to Index:');
    console.log(updatesToIndex);
    setUpdatesIndex(updatesToIndex);
  }, [chase, HOST]);

  useEffect(() => {
    if (Array.isArray(chase.events)) {
      if (chase.events.length) {
        fetchUpdates();
      } else {
        setUpdates([]);
      }
    }
  }, [chase.events, fetchUpdates]);

  useEffect(() => {
    fetchData();
  }, []);

  const index = updates && updates.findIndex((u) => u.id === update.id);
  const nextUpdate = (() => {
    if (index === updates.length) return false;
    return updates[index + 1];
  })();
  const prevUpdate = (() => {
    if (index === 0) return false;
    return updates[index - 1];
  })();

  return (
    <div className="HHMap HHMap--replay">
      {loaded ? (
        <>
          <Panel
            distance={chase.meta.chase_distance}
            duration={chase.meta.chase_duration}
            events={chase.meta.chase_events.length}
            started={{
              location: chase.meta.chase_start_address,
              timestamp: chase.route[0].timestamp,
            }}
            ended={{
              location: chase.meta.chase_end_address,
              timestamp: chase.route[chase.route.length - 1].timestamp,
            }}
          />
          <div className="HHReplay">
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken={token}
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/highwaysandhailstones/cjynsjl5z405f1cmqfym2bxac"
              onViewportChange={(nextViewport) => setViewport(nextViewport)}
              scrollZoom={false}
              dragRotate={false}
              touchRotate={false}
              mapOptions={{
                bounds: chase.bounds,
                scrollZoom: false,
                touchPitch: false,
                dragRotate: false,
                dragPan: false,
                touchZoomRotate: false,
                fitBoundsOptions: {
                  padding: {
                    top: window.innerHeight * 0.15,
                    bottom: window.innerHeight * 0.15,
                    left: window.innerWidth * 0.1,
                    right: window.innerWidth * 0.1,
                  },
                },
              }}
              attributionControl={false}
            >
              <Source type="geojson" data={chase.geoJSON}>
                <Layer
                  {...{
                    id: 'outer-path',
                    type: 'line',
                    source: 'line',
                    layout: {
                      'line-cap': 'round',
                      'line-join': 'round',
                    },
                    paint: {
                      'line-color': '#000000',
                      'line-width': 6,
                      'line-opacity': 1,
                    },
                  }}
                />
              </Source>
              <Source
                type="geojson"
                data={progressGeojson ? progressGeojson : chase.geoJSON}
              >
                <Layer
                  {...{
                    id: 'inner-path',
                    type: 'line',
                    source: 'line',
                    layout: {
                      'line-cap': 'round',
                      'line-join': 'round',
                    },
                    paint: {
                      'line-color': '#0071C2',
                      'line-width': 4,
                      'line-opacity': 1,
                    },
                  }}
                />
              </Source>

              {oldRadar ? (
                <>
                  {oldRadar !== 'loading' && (
                    <Source
                      id="radar"
                      type="image"
                      url={oldRadar.data}
                      coordinates={radarCoords}
                    >
                      <Layer id="rain" type="raster" beforeId="place-hamlet" />
                    </Source>
                  )}
                  <DebugPanel
                    time={oldRadar.time}
                    old={true}
                    loading={oldRadar === 'loading'}
                  />
                </>
              ) : (
                ''
              )}

              {updates &&
                updates.map((u) => {
                  return (
                    <Update
                      key={u.id}
                      id={u.id}
                      update={u}
                      location={{
                        lat: u.meta.chase_latitude,
                        lng: u.meta.chase_longitude,
                      }}
                      chaser_id={parseInt(chase.author)}
                      handleSetUpdate={handleSetUpdate}
                      selectedUpdate={
                        update.hasOwnProperty('update')
                          ? parseInt(update.update.id)
                          : 0
                      }
                    />
                  );
                })}

              {chase && chase.route.length > 2 && rabbit && (
                <Marker
                  latitude={chase.route[rabbit].lat}
                  longitude={chase.route[rabbit].lon}
                  offsetLeft={-8}
                  offsetTop={-8}
                >
                  <span class="Rabbit"></span>
                </Marker>
              )}
              {(progress || progress === 0) && !update && (
                <Marker
                  latitude={chase.route[indexFromProgress(progress)].lat}
                  longitude={chase.route[indexFromProgress(progress)].lon}
                  offsetTop={-22}
                  offsetLeft={-20}
                >
                  <div className="Avatar">
                    <Avatar chaser={chaser} />
                  </div>
                </Marker>
              )}
              <NavigationControl
                showCompass={false}
                style={{ right: 10, bottom: 10 }}
              />
            </ReactMapGL>
            <div className="Sidebar">
              {update && (
                <UpdatePanel
                  {...update}
                  handleSetUpdate={handleSetUpdate}
                  nextUpdate={nextUpdate}
                  prevUpdate={prevUpdate}
                  chaser={chaser}
                />
              )}
              {livestream && (
                <LiveStreamPanel
                  handleSetUpdate={() => {
                    setLivestream(false);
                  }}
                  liveReplay={true}
                >
                  <Video
                    key={livestream}
                    playback_id={livestream}
                    liveReplay={true}
                    handleSetProgress={handleSetProgress}
                  />
                </LiveStreamPanel>
              )}
            </div>
          </div>

          {chase && updates ? (
            <Timeline
              chase={chase}
              update={update}
              updates={updates}
              updatesIndex={updatesIndex}
              progress={progress}
              handleSetUpdate={handleSetUpdate}
              handleSetProgress={handleSetProgress}
              handleSetRabbit={handleSetRabbit}
              handleSetLivestream={handleSetLivestream}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default HHReplay;
