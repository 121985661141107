import React from 'react';
import Video from './Video';
import Avatar from './Avatar';
import close from '../svg/close.svg';
import { ReactComponent as LarrSVG } from '../svg/larr.svg';
import { ReactComponent as RarrSVG } from '../svg/rarr.svg';
import { ReactComponent as XSVG } from '../svg/x.svg';

export default function UpdatePanel(props) {
  function thumbnail() {
    if (props.type === 'video') return props.update.meta?.preview;
    if (
      (props.type === 'image' ||
        props.type === 'media' ||
        props.type === 'attachment') &&
      props.update.media_type !== 'file'
    ) {
      if (props.update.media_details?.sizes?.small) {
        // TODO: duplicate here. Look for other instances of source_url
        return props.update.media_details.sizes.small.source_url;
      } else {
        return props.update.source_url;
      }
    }
  }

  // TODO: if the chaser stops chasing while this panel is open, it stays on the screen
  // I think the same happens for Livestreaming, so look into a solution there too
  return (
    <div className="UpdatePanel">
      <div className={`Update__meta Update__meta--${props.type}`}>
        <div className="Update__controls">
          <div>
            <button
              onClick={() =>
                props.handleSetUpdate({
                  update: props.prevUpdate,
                  id: props.prevUpdate.id,
                  type: props.prevUpdate.type,
                })
              }
              disabled={!props.prevUpdate}
            >
              <LarrSVG />
            </button>
            <button
              onClick={() =>
                props.handleSetUpdate({
                  update: props.nextUpdate,
                  id: props.nextUpdate.id,
                  type: props.nextUpdate.type,
                })
              }
              disabled={!props.nextUpdate}
            >
              <RarrSVG />
            </button>
          </div>
          <button onClick={() => props.handleSetUpdate(false)}>
            <XSVG />
          </button>
        </div>
        <div className="Update__content">
          <div className="Update__chaser">
            <figure>{props.chaser && <Avatar chaser={props.chaser} />}</figure>
            <div>
              <p>
                <strong>{props.chaser?.name}</strong>
              </p>
              <p>
                {props.update.meta?.chase_address} ·{' '}
                {new Date(props.update.date_gmt + '.000+00:00')
                  .toISOString()
                  .substr(11, 5)}{' '}
                Z
              </p>
            </div>
          </div>
          <figure>
            {(props.type === 'media' || props.type === 'attachment') && (
              <img src={thumbnail()} alt="" />
            )}
            {props.type === 'video' && <Video url={props.update.meta?.url} />}
            {props.update.caption ? (
              <figcaption
                dangerouslySetInnerHTML={{
                  __html: props.update.caption.rendered,
                }}
                className="Update__text"
              />
            ) : (
              props.update.meta?.caption && (
                <figcaption className="Update__text">
                  {props.update.meta?.caption}
                </figcaption>
              )
            )}
            {props.type === 'text' && (
              <figcaption
                dangerouslySetInnerHTML={{
                  __html: props.update.content.rendered,
                }}
                className="Update__text"
              />
            )}
          </figure>
        </div>
      </div>
    </div>
  );
}
