const API_KEY = 'Rpw75Ivywx3r0kot3Czk65igBIhb04z7OmIGiPxe';
const endpoint = function (timestamp) {
  var type = 'precip';
  var resolution = 4096;
  var filename = `radar_northamerica3857_${type}_${timestamp}.png`;
  console.log(timestamp);

  return `https://api.weatherlogics.com/radar/v1/weatherlogics-radar/${type}/${resolution}/${filename}`;
};

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

function getRadarAsBase64(date, timesback = 2) {
  // Check if date is a string or date is a date
  try {
    if (!(date instanceof Date)) date = new Date(date);

    var coeff = 1000 * 60 * 10;
    var rounded = new Date(
      Math.round((date.getTime() - coeff * timesback) / coeff) * coeff
    );
    var timestamp = rounded.toISOString().replace('.000Z', 'Z');
    console.log(`Attempting to access ${timesback}`);
    return fetch(endpoint(timestamp), {
      headers: { 'x-api-key': API_KEY },
    })
      .then((response) => response.blob())
      .then(convertBlobToBase64)
      .then((base64) => {
        return {
          data: base64,
          time: rounded,
        };
      })
      .catch((error) => {
        console.log(`Failed Access ${timesback}`);
        if (timesback < 4) {
          return getRadarAsBase64(date, timesback + 1);
        }
      });
  } catch (e) {}
}

const radarCoords = [
  [-130, 60], // top left
  [-52, 60], // top right
  [-52, 24], // bottom right
  [-130, 24], // bottom left
];

export { getRadarAsBase64, radarCoords };
