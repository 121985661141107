import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { useState } from 'react';

import HHReplay from './HHReplay';
import HHMap from './HHMap';
import HHWall from './HHWall';

import 'modern-normalize/modern-normalize.css';
import './App.css';

class App extends React.Component {
  render() {
    var CHASE_ID = false;
    if (window.location.hash && !isNaN(window.location.hash.replace('#', ''))) {
      CHASE_ID = window.location.hash.replace('#', '');
    }
    return (
      <Router>
        <Switch>
          <Route path="/embed/:station/map/:approved">
            <HHMap />
          </Route>
          <Route path="/embed/:station/wall/:approved">
            <HHWall />
          </Route>

          <Route path="/replay/:chase_id/update/:update_id">
            <HHReplay />
          </Route>
          <Route path="/replay/:chase_id">
            <HHReplay />
          </Route>

          <Route path="/chase/:chase_id/update/:update_id">
            <HHMap />
          </Route>

          <Route path="/">
            <HHMap />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
