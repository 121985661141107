import React, { useState, useEffect } from 'react';
import Video from './Video';
import Avatar from './Avatar';
import close from '../svg/close.svg';
import { ReactComponent as LarrSVG } from '../svg/larr.svg';
import { ReactComponent as RarrSVG } from '../svg/rarr.svg';
import { ReactComponent as XSVG } from '../svg/x.svg';

export default function FetchableUpdatePanel(props) {
  const [update, setUpdate] = useState(false);
  const [prevUpdate, setPrevUpdate] = useState(false);
  const [nextUpdate, setNextUpdate] = useState(false);

  function fetchUpdate(type, id) {
    if (type === 'image' || type === 'attachment') type = 'media';
    const url = `${process.env.REACT_APP_HOST}/wp-json/wp/v2/${type}/${id}`;

    fetch(url)
      .then((r) => r.json())
      .then((data) => {
        setUpdate(data);
        console.log(data);
        props.handleSetUpdate({
          ...props.update,
          id: data.id,
          update: {
            ...data,
          },
        });
      });
  }

  useEffect(() => {
    console.log('mounting fetchable');
    console.log(props.update.skip_fetch);
    if (props.update && props.update.skip_fetch) {
      console.log(props.update);
      setUpdate(props.update.update);
    } else {
      if (!update) {
        fetchUpdate(props.type, props.id);
      }
    }
  }, []);

  function thumbnail(update) {
    if (update.type === 'video') return update.meta?.preview;
    if (
      (update.type === 'image' ||
        update.type === 'media' ||
        update.type === 'attachment') &&
      update.media_type !== 'file'
    ) {
      if (update.media_details?.sizes?.small) {
        // TODO: duplicate here. Look for other instances of source_url
        return update.media_details.sizes.small.source_url;
      } else {
        return update.source_url;
      }
    }
  }

  useEffect(() => {
    const index =
      props.updates &&
      props.updates.findIndex((u) => u.update.id === update.id);
    console.log(index);
    console.log(props.updates.length);
    if (index > -1) {
      setNextUpdate(
        (() => {
          if (index === props.updates.length - 1) return false;
          return props.updates[index + 1];
        })()
      );
      setPrevUpdate(
        (() => {
          if (index === 0) return false;
          return props.updates[index - 1];
        })()
      );
    }
  }, [update, props.updates]);

  console.log({ prev: prevUpdate, next: nextUpdate });

  // TODO: if the chaser stops chasing while this panel is open, it stays on the screen
  // I think the same happens for Livestreaming, so look into a solution there too
  return (
    <div className="UpdatePanel">
      {update && (
        <div className={`Update__meta Update__meta--${update.type}`}>
          <div className="Update__controls">
            <div>
              <button
                onClick={() =>
                  fetchUpdate(prevUpdate.update.type, prevUpdate.update.id)
                }
                disabled={!prevUpdate}
              >
                <LarrSVG />
              </button>
              <button
                onClick={() =>
                  fetchUpdate(nextUpdate.update.type, nextUpdate.update.id)
                }
                disabled={!nextUpdate}
              >
                <RarrSVG />
              </button>
            </div>
            {props.chaser.live && (
              <a
                className="Update__golive"
                onClick={() => props.handleSetVideo(props.update.chaser_id)}
              >
                Watch Live Stream
              </a>
            )}
            <button onClick={() => props.handleSetUpdate(false)}>
              <XSVG />
            </button>
          </div>
          <div className="Update__content">
            <div className="Update__chaser">
              <figure>
                {props.chaser && <Avatar chaser={props.chaser} />}
              </figure>
              <div>
                <p>
                  <strong>{props.chaser?.name}</strong>
                </p>
                <p>
                  {update.meta?.chase_address} ·{' '}
                  {update.date_gmt &&
                    new Date(update.date_gmt + '.000+00:00')
                      .toISOString()
                      .substr(11, 5)}{' '}
                  Z
                </p>
              </div>
            </div>
            <figure>
              {(update.type === 'media' || update.type === 'attachment') && (
                <img src={thumbnail(update)} alt="" />
              )}
              {update.type === 'video' && update.meta && (
                <Video
                  url={update.meta?.url}
                  video_id={update.id}
                  video_title={`${props.chaser?.name} Update`}
                />
              )}
              {update.caption ? (
                <figcaption
                  dangerouslySetInnerHTML={{
                    __html: update.caption.rendered,
                  }}
                  className="Update__text"
                />
              ) : (
                update.meta?.caption && (
                  <figcaption className="Update__text">
                    {update.meta?.caption}
                  </figcaption>
                )
              )}
              {update.type === 'text' && update.content && (
                <figcaption
                  dangerouslySetInnerHTML={{
                    __html: update.content.rendered,
                  }}
                  className="Update__text"
                />
              )}
            </figure>
          </div>
        </div>
      )}
    </div>
  );
}
