import React from 'react';
import Video from './Video';
import close from '../svg/close.svg';

export default function LiveStreamPanel(props) {
  function thumbnail() {
    if (props.type === 'video') return props.update.meta.preview;
    if (props.type === 'image' || props.type === 'media') {
      if (props.update.media_details?.sizes?.small) {
        // TODO: duplicate here. Look for other instances of source_url
        return props.update.media_details.sizes.small.source_url;
      } else {
        return props.update.source_url;
      }
    }
  }
  // TODO: if the chaser stops chasing while this panel is open, it stays on the screen
  // I think the same happens for Livestreaming, so look into a solution there too
  return (
    <div
      className={`UpdatePanel LiveStreamPanel ${
        props.liveReplay ? 'replay' : ''
      }`}
    >
      <div className="Update__meta">
        <button
          href="#"
          className="Update__close"
          onClick={() => props.handleSetUpdate()}
        >
          <img src={close} alt="" />
        </button>
        <div>
          <figure>{props.children}</figure>
        </div>
      </div>
    </div>
  );
}
